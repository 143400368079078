import React from 'react';
import {TextField, TextFieldProps}  from '@material-ui/core';


interface IProps{
    textFieldProps:TextFieldProps
}

export const InputField=(props:IProps)=>{

    
    const {
        textFieldProps
    } = props;

    return(
      <>
        <TextField
            // className={classes.textField}
            {
                ...textFieldProps
            }
            />
      </>
    );
};  