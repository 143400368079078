import {
    Button,
    Grid,
    Hidden,
    makeStyles,
    Typography,
    Checkbox,
    FormControlLabel,
    Link as MLink,
    MenuItem, Select,InputAdornment
} from "@material-ui/core";
import {Link} from 'react-router-dom';
import {InputField} from "../Form";
import React, {useEffect} from "react";
import bBG from "../../assets/haribo/body_background.png";
import fBG from "../../assets/haribo/form_background.png";
import logo from "../../assets/haribo/logo.png";
import prize from "../../assets/haribo/prize.png";
import ps1 from "../../assets/haribo/ps51.png";
import iphone1 from "../../assets/haribo/iphone1.png";
import psDetail from "../../assets/haribo/psDetail.png";
import iphoneDetail from "../../assets/haribo/iphoneDetail.png";
import packet from "../../assets/haribo/packet2.png";
import bear from "../../assets/haribo/bear1.png";
import {useTranslation} from "react-i18next";
import countryToCities from '../../assets/others/countryToCities.json';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(theme => ({
    body: {
        flex: 1,
        backgroundImage:`url(${bBG})`,
        backgroundSize:'100% 100%',
        backgroundRepeat:'no-repeat',
    },
    formContainer: {
        height:'100%',
        padding: '4rem',
        [theme.breakpoints.down("sm")]: {
            padding:'2rem'
        },
    },
    formHeading:{
      textAlign:'center',
      background:theme.palette.primary.dark,
      padding:'2rem',
      borderRadius:'50px',
      marginBottom:'5rem'

    },
    form:{
        zIndex:1,
        padding:'20%',
        [theme.breakpoints.down("xs")]: {
            padding:'0'
        },
    },
    userItems:{
        position:'relative',
        display:'flex',
        zIndex:1,
    },
    formCover:{
        position:'absolute',
        top:'0',
        left:'0',
        width:'100%',
        height:'100%',
        backgroundImage:`url(${fBG})`,
        backgroundSize:'100% 100%',
        backgroundRepeat:'no-repeat',
    },
    logo:{
        flip:false,
        textAlign:'left'
    },
    termsLink:{
      color:theme.palette.secondary.main,
    },
    direction:{
        '& .MuiInputBase-root':{
            flip:false,
            direction:'ltr'
        },
    }
}));
interface IUProps {
    formik: any;
    goBack:()=>void;
}

export const UserDetail = (props: IUProps) => {
    const {formik,goBack} = props;
    const classes = useStyles();
    useEffect(()=>{
        return ()=>{
            formik.resetForm();
        }
    },[]);
    const {t}=useTranslation();
    const cities:Array<string>=(countryToCities as any)[formik.values.country]||[];

    return (
        <div className={classes.body}>
        <Grid container className={classes.formContainer} justifyContent={'center'}>
            <Grid className={classes.logo} item xs={12} sm={12} md={12} lg={12} xl={12}><img onClick={goBack} src={logo} style={{maxWidth:'100%',cursor:'pointer'}}/>
               </Grid>
            <Grid className={classes.userItems} item xs={12} sm={12} md={10} lg={8} xl={8}>
                <Hidden xsDown={true}>
                    <span className={classes.formCover}></span>
                </Hidden>
                <Hidden smDown={true}>
                <img src={prize} style={{maxWidth:'36%',position:'absolute',top:'-5%',right:'-15%',zIndex:2}}/>
                <img src={bear} style={{maxWidth:'25%',position:'absolute',top:'11%',left:'0%',zIndex:2}}/>
                <img src={iphone1} style={{maxWidth:'30%',position:'absolute',top:'42%',right:'85%',transform:'rotate(-20deg)',zIndex:-1}}/>
                <img src={ps1} style={{maxWidth:'30%',position:'absolute',top:'30%',left:'88%',transform:'rotate(20deg) scaleX(-1)',zIndex:-1}}/>
                <img src={packet} style={{maxWidth:'20%',position:'absolute',top:'60%',left:'87%',transform:'rotate(20deg)',zIndex:-1}}/>
                <img src={packet} style={{maxWidth:'20%',position:'absolute',top:'70%',right:'92%',transform:'rotate(-20deg)',zIndex:-1}}/>
                </Hidden>

                <form className={classes.form} onSubmit={formik.handleSubmit}>
                    <Typography component={'p'} variant={'h2'} className={classes.formHeading}>{t('formHeading')}
                        {formik.values.country==='Egypt'?<>
                        <br/>
                            <Typography variant={'h3'} component={'p'}>( {t('extension')} )</Typography>
                        </>:null}
                    </Typography>
                    <InputField textFieldProps={{
                    label: t('name'),
                    value: formik.values.name,
                    variant:'filled',
                    name:'name',
                    fullWidth: true,
                    required:true,
                    onChange: formik.handleChange,
                    // error: formik.touched.name && Boolean(formik.errors.name),
                    // helperText: formik.touched.name && formik.errors.name
                }}/>
                <InputField textFieldProps={{
                    label: t('email'),
                    value: formik.values.email,
                    type:'email',
                    name:'email',
                    variant:'filled',
                    fullWidth: true,
                    required:true,
                    onChange: formik.handleChange,
                    // error: formik.touched.email && Boolean(formik.errors.email),
                    // helperText: formik.touched.email && formik.errors.email
                }}/>
                    <Select
                        value={formik.values.city}
                        style={{margin: "1rem 0"}}
                        variant={'outlined'}
                        displayEmpty
                        name={'city'}
                        IconComponent={()=><ArrowDropDown/>}
                        required
                        onChange={formik.handleChange}
                        fullWidth
                    >
                        <MenuItem value={''} disabled><span style={{color:'rgba(0, 0, 0, 0.54)'}}>{t(formik.values.country==='UAE'?'emirate':'city')} *</span></MenuItem>
                        {cities.map((item:string,index:number)=><MenuItem key={`${item}-${index}`} value={item}>{t(item)}</MenuItem>)}
                        <MenuItem value={'other'}>{t('Other')}</MenuItem>
                    </Select>
                    {formik.values.city==='other'?<InputField textFieldProps={{
                        label: t('city'),
                        value: formik.values.other,
                        variant:'filled',
                        name:'other',
                        fullWidth: true,
                        required:true,
                        onChange: formik.handleChange,
                        // error: formik.touched.name && Boolean(formik.errors.name),
                        // helperText: formik.touched.name && formik.errors.name
                    }}/>:null}
                <InputField textFieldProps={{
                    label: t('contact'),
                    value: formik.values.mobileNumber,
                    name:'mobileNumber',
                    variant:'filled',
                    fullWidth: true,
                    className:classes.direction,
                    required:true,
                    type:'number',
                    InputProps:{startAdornment:<InputAdornment position={'start'}>
                            <span style={{display:'flex',alignItems:'center'}}><img style={{maxWidth:'2rem'}} src={formik.values.flag}/>{formik.values.countryCode}</span>
                        </InputAdornment>},
                    onChange: formik.handleChange,
                    // error: formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber),
                    // helperText: formik.touched.mobileNumber && formik.errors.mobileNumber
                }}/>
                <InputField textFieldProps={{
                    label: t('code'),
                    value: formik.values.code,
                    inputProps:{maxLength:16,minLength:16,pattern:'^[A-Z0-9]+$'},
                    name:'code',
                    className:classes.direction,
                    variant:'filled',
                    fullWidth: true,
                    required:true,
                    onChange:(e:any)=>{
                        formik.setFieldValue('code',e.target.value.toUpperCase())},
                    helperText:t('codeHelper')
                    // error: formik.touched.code && Boolean(formik.errors.code),
                    // helperText: formik.touched.code && formik.errors.code
                }}/>
                <FormControlLabel style={{marginTop:'4rem'}} control={<Checkbox required={true} color={"secondary"} />} label={<span>{t('agree')} <Link className={classes.termsLink} to={'/terms'} target={'_blank'}>{t('conditions')}</Link></span>} />
                <Button color="primary" style={{margin:'1rem 0'}} variant="contained" disabled={formik.isSubmitting} fullWidth type="submit">
                    {t('submit')}
                </Button>
                <Typography style={{textAlign:'center'}} variant={'subtitle2'} component={'p'}>{t('queries')}<br/>
                    <MLink href={'mailto:contact@goldbearsprizes.com'} color={'secondary'}>contact@goldbearsprizes.com</MLink></Typography>
                </form>
            </Grid>
            <Grid item container justifyContent={'center'} xs={12} sm={12} md={12} lg={12} xl={12}>
                <img src={psDetail} style={{maxWidth:'50%'}}/>
                <img src={iphoneDetail} style={{maxWidth:'50%'}}/>
            </Grid>
        </Grid>
        </div>
    );
};