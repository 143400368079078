import React from 'react';
import {makeStyles} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
    frame:{
        width:'100%',
        height:'100%',
        border:'none'
    },
    termsContainer:{
      height: 'inherit',
      overflow:'hidden'
    }
}));

export const TermsPage=()=> {
    const classes=useStyles();
    return (
        <div className={classes.termsContainer}>
        <iframe className={classes.frame} src={`./terms/Terms-and-Conditions.html`}/>
        </div>
    );
};
