import React, {useEffect, useState} from 'react';
import './App.css';
import {HashRouter,Switch,Route} from 'react-router-dom';
import {LandingPage,TermsPage} from "./pages";

function App() {

    return (
        <HashRouter>
            <Switch>
                <Route path={'/'} exact component={LandingPage}/>
                <Route path={'/terms'} exact component={TermsPage}/>
            </Switch>
        </HashRouter>
    );
}

export default App;
