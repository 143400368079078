import React from 'react';
import {Typography, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import egypt from '../../assets/img/egypt.png'
import arab from '../../assets/img/arab.png';
import oman from '../../assets/img/oman.png';
import kuwait from '../../assets/img/kuwait.png';
import qatar from '../../assets/img/qatar.png';
import bahrain from '../../assets/img/bahrain.png';
import hBG from '../../assets/haribo/header_background.png';
import logo from '../../assets/haribo/logo.png';
import prize from '../../assets/haribo/prize.png';
import packet2 from '../../assets/haribo/packet2.png';
import bear1 from '../../assets/haribo/bear1.png';
import cBG from '../../assets/haribo/country_background.png';
import {useTranslation} from "react-i18next";
const useStyles = makeStyles(theme => ({
    header:{
        position:'relative'
    },
    body: {
        flex: 1,
        padding:'4rem',
        background: theme.palette.primary.dark
    },
    formContainer: {
        [theme.breakpoints.up("lg")]: {
            backgroundImage:`url(${cBG})`,
            backgroundSize:'100% 100%',
            backgroundRepeat:'no-repeat',
            padding:'10rem'
        },
    },
    countryItems: {
        textAlign: 'center',
        '& img': {
            height: 'auto',
            maxWidth: '100%',
            opacity: '1',
            webkitTransition: '.3s ease-in-out',
            transition: '.3s ease-in-out',
            '&:hover': {
                cursor: 'pointer',
                opacity: '.5'
            }
        }
    },
}));
interface IProps {
    handleClick: (name: string,code:string,icon:any) => void;
}
export const Country = (props: IProps) => {
    const classes = useStyles();
    const {t}=useTranslation();
    const {handleClick} = props;
    return (
        <>
                <div className={classes.header}>
                    <img src={hBG} style={{height:'100%',width:'100%'}}/>
                    <img src={logo} style={{width:'30%',position:'absolute',top:'3%',left:'3%'}}/>
                    <img src={packet2} style={{width:'18%',position:'absolute',bottom:'19%',left:'47%',transform:'rotate(-25deg)'}}/>
                    <img src={packet2} style={{width:'20%',position:'absolute',bottom:'15%',left:'52%'}}/>
                    <img src={prize} style={{width:'30%',position:'absolute',top:'7%',right:'3%'}}/>
                    <img src={bear1} style={{width:'25%',position:'absolute',bottom:'0',left:'3%'}}/>
                </div>
                <div className={classes.body}>
                    <Typography style={{textAlign:'center',marginBottom:'2rem'}} component={'p'}
                                variant={'h1'}>{t('selectCountry')}</Typography>
                    <Grid container className={classes.formContainer} justifyContent={'center'} alignItems={'center'}>
                            <Grid className={classes.countryItems} item xs={12} sm={6} md={6} lg={4} xl={4}><img src={egypt}
                                                                                                                  onClick={() => handleClick('Egypt','+20',egypt)}/><Typography
                                component={'p'} variant={'subtitle1'}>{t('egypt')}</Typography></Grid>
                            <Grid className={classes.countryItems} item xs={12} sm={6} md={6} lg={4} xl={4}><img src={bahrain}
                                                                                                                  onClick={() => handleClick('Bahrain','+973',bahrain)}/><Typography
                                component={'p'} variant={'subtitle1'}>{t('bahrain')}</Typography></Grid>
                            <Grid className={classes.countryItems} item xs={12} sm={6} md={6} lg={4} xl={4}><img src={kuwait}
                                                                                                                  onClick={() => handleClick('Kuwait','+965',kuwait)}/><Typography
                                component={'p'} variant={'subtitle1'}>{t('kuwait')}</Typography></Grid>
                            <Grid className={classes.countryItems} item xs={12} sm={6} md={6} lg={4} xl={4}><img src={oman}
                                                                                                                  onClick={() => handleClick('Oman','+968',oman)}/><Typography
                                component={'p'} variant={'subtitle1'}>{t('oman')}</Typography></Grid>
                            <Grid className={classes.countryItems} item xs={12} sm={6} md={6} lg={4} xl={4}><img src={arab}
                                                                                                                  onClick={() => handleClick('UAE','+971',arab)}/><Typography
                                component={'p'} variant={'subtitle1'}>{t('uae')}</Typography></Grid>
                            <Grid className={classes.countryItems} item xs={12} sm={6} md={6} lg={4} xl={4}><img src={qatar}
                                                                                                                  onClick={() => handleClick('Qatar','+974',qatar)}/><Typography
                                component={'p'} variant={'subtitle1'}>{t('qatar')}</Typography></Grid>
                    </Grid>
                </div>
            </>
    );
};
