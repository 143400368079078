import {createTheme, ThemeOptions} from "@material-ui/core";

export const appTheme = (direction:string): ThemeOptions => {
    return (createTheme({
        direction:direction as any,
        palette: {
            primary: {
                main: '#F1B034',
                light: '#1380C7',
                dark: '#0072BC'
            },
            secondary: {
                main: '#0072BC'
            }
        },
        typography: {
            fontFamily: 'hariboFont',
            h1: {
                color: '#fff',
                fontSize: '2.5rem',
                fontFamily: 'hariboFontBold',
            },
            h2: {
                color: '#fff',
                fontSize: '2rem',
                fontFamily: 'hariboFontBold',
            },
            h3: {
                color: '#fff',
                fontSize: '0.9rem',
                fontFamily: 'hariboFontBold',
            },
            h4: {
                color: '#fff',
                fontSize: '0.9rem'
            },
            subtitle1: {
                color: '#000',
                fontSize: '1.2rem',
                fontFamily: 'hariboFontBold',

            },
            subtitle2: {
                color: '#000',
                fontSize: '1rem',
            },
        },
        breakpoints: {},
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    html: {
                        '@media (max-width: 1000px)': {
                            fontSize: '12px'
                        },
                        '@media (min-width: 2048px)': {
                            fontSize: '20px'
                        },
                    },
                }
            },
            MuiButton: {
                root: {
                    height: "3.2rem",
                    fontSize:'1.2rem',
                    textTransform: "capitalize",
                    borderRadius: '120px'
                },
                containedPrimary: {
                    backgroundColor: "#0072BC",
                    color: 'white',
                    textTransform: "capitalize",
                    "&:hover": {
                        opacity: '0.8'
                    }
                },
            },
            MuiTextField: {
                root: {
                    margin: "1rem 0",
                }
            },
            MuiInputLabel: {
                root: {
                    fontSize: '1rem',
                    fontWeight: "normal",
                    "&.Mui-focused:not(.Mui-error)": {
                        color: "#0072BC"
                    },
                    "& + .MuiInput-formControl": {}
                },
            },
            MuiFormHelperText: {
                root: {
                    marginTop: '0.2rem'
                }
            },
            MuiInputBase: {
                root: {
                    background: '#EAF2FB',
                    borderRadius: '30px !important'
                }
                ,
            },
            MuiFilledInput: {
                root: {
                    backgroundColor: '#EAF2FB',
                    '&:hover,&:focus': {
                        backgroundColor: '#EAF2FB'
                    }
                },
                underline: {
                    '&:after,&:before,&:hover:before': {
                        borderBottom: 'none',
                    }
                }
            },
            MuiSelect:{
                outlined:{
                    paddingLeft:'12px !important'
                }
            }
        }
    }));
};
