import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {appTheme} from "./styles";
import { ThemeProvider,jssPreset,StylesProvider} from "@material-ui/styles";
import {CssBaseline} from "@material-ui/core";
import {englishTranslation,arabicTranslation} from "./utils";
import i18 from 'i18next';
import {initReactI18next,useTranslation} from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import Backend from 'i18next-http-backend';
import { create } from 'jss';
import rtl from 'jss-rtl';
i18.use(initReactI18next).init({resources:{en:{translation:englishTranslation},ar:{translation:arabicTranslation}},lng:'en',fallbackLng:'en',interpolation:{escapeValue:false}});
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const HOC=()=>{
    const {i18n}=useTranslation();
    return(
        <ThemeProvider theme={appTheme(i18n.dir())}>
            <App />
        </ThemeProvider>
    );
};
ReactDOM.render(
  <React.StrictMode>
      <StylesProvider jss={jss}>
          <CssBaseline/>
          <HOC/>
      </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
