import React, {useEffect, useState} from 'react';
import {
    Typography,
    Grid,
    Container,
    Link as MLink,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button
} from "@material-ui/core";
import {Link} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import bear2 from '../../assets/haribo/bear2.png';
import {useFormik} from 'formik';
import * as Yup from 'yup'
import {Country, UserDetail} from "../../components";
import {useTranslation} from "react-i18next";
import {addUser} from "../../utils";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles(theme => ({
    parent: {
        padding: 0,
        height: 'inherit',
        maxWidth: '1600px'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: 'inherit',
        position: 'relative'
    },
    footer: {
        background: theme.palette.primary.light,
        padding: '1.5rem 1.5rem 0 1.5rem',
    },
    footerUnderline: {
        background: '#00548B',
        borderTop: '2px solid white',
        textAlign: 'center',
        padding: '1rem'
    },
    userItems: {
        padding: '1rem',
    },
    languageSelect: {
        background: 'transparent',
        color: 'white',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        [theme.breakpoints.down('md')]: {
            '& .MuiSelect-outlined': {
                paddingTop: '0',
                paddingBottom: '0'
            }
        },
        flip: false,
        right: 0,
    },
    termsLink: {
        textDecoration: 'none',
        color: 'inherit'
    }
}));
const addUserSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    code: Yup.string().matches(/^[A-Z0-9]+$/i, 'Must Contain 16 Characters,Uppercase And Number Only').length(16).required('Required'),
    city: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    mobileNumber: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
});
export const LandingPage = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const {t, i18n} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const formik = useFormik({
        initialValues: {
            name: '',
            code: '',
            email: '',
            city: '',
            country: '',
            mobileNumber: '',
            countryCode: '',
            flag: null,
            other: ''
        },
        onSubmit: async (values) => {
            try {
                const payload: any = {...values};
                payload.mobileNumber = payload.countryCode + payload.mobileNumber;
                if (payload.city === 'other')
                    payload.city = payload.other;
                delete payload.other;
                delete payload.countryCode;
                delete payload.flag;
                const resp = await addUser(payload);
                if (resp.status === 200) {
                    if (resp.data.statusCode === 200) {
                        setOpen(true);
                        return;
                    }

                    toast(resp.data.body.message, {type: 'info'});
                }
            } catch (e) {
                toast('Internal Server Error', {type: 'error'});
            }
        },
        validationSchema: addUserSchema,
        enableReinitialize: false
    });
    const handleClose = () => {
        setActiveStep(0);
        setOpen(false);
    };
    const handleCountryChange = (name: string, code: string, icon: any) => {
        formik.setFieldValue('country', name);
        formik.setFieldValue('countryCode', code);
        formik.setFieldValue('flag', icon);
        setActiveStep(activeStep + 1);

    };
    useEffect(() => {
        document.getElementById('root')!.scrollTo(0, 0);
    }, [activeStep]);
    const steps: Array<React.ReactElement> = [<Country handleClick={handleCountryChange}/>,
        <UserDetail formik={formik} goBack={() => {
            setActiveStep(0)
        }}/>];
    return (
        <Container
            component={"div"}
            className={classes.parent}
            maxWidth={'xl'}

        >
            <div className={classes.container}>
                <Select
                    value={i18n.language}
                    variant={'outlined'}
                    IconComponent={() => <ArrowDropDown/>}
                    className={classes.languageSelect}
                    onChange={(event: any) => {
                        i18n.changeLanguage(event.target.value);
                        document.body.dir = i18n.dir();
                    }}

                >
                    <MenuItem value={'en'}>English</MenuItem>
                    <MenuItem value={'ar'}>Arabic</MenuItem>
                </Select>
                {steps[activeStep]}
                <div className={classes.footer}>
                    <Grid container alignItems={'center'}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            {/*<Typography component={'p'} style={{marginBottom: '1rem'}}*/}
                            {/*            variant={'h3'}>{t('contact')}</Typography>*/}
                            {/*<Typography component={'p'} style={{marginBottom: '1rem'}}*/}
                            {/*            variant={'h3'}>{t('privacy')}</Typography>*/}
                            <div>
                                <Typography component={'p'} variant={'h3'}><Link className={classes.termsLink}
                                                                                 to={'/terms'}
                                                                                 target={'_blank'}>{t('terms')}</Link>
                                    <Typography component={'p'}  style={{margin: '1rem 0'}}
                                                variant={'h3'}>{t('website')}: <MLink href={'https://www.haribo.com'}
                                                                                      color={'inherit'}
                                                                                      variant={'h4'}>https://www.haribo.com</MLink>
                                    </Typography>
                                    <Typography component={'p'} variant={'h4'}>Copyright ©
                                        Haribo | All rights reserved</Typography>
                                </Typography>


                            </div>
                        </Grid>
                        <Grid style={{display: 'flex', justifyContent: 'end'}} item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <img style={i18n.dir() === 'rtl' ? {transform: 'scaleX(-1)'} : undefined} src={bear2}/>
                        </Grid>
                    </Grid>
                </div>
                <Typography component={'p'} className={classes.footerUnderline}
                            variant={'h3'}>{t('tagLine')}</Typography>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Haribo
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Thank you for participating in Haribo GoldBears Raffle Draw,
                        <br/>
                        We wish you best of luck!
                        <br/>
                        Team Haribo
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer/>
        </Container>
    );
};
