export const englishTranslation = {
    tagLine:'Kids and grown-ups love it so, the happy world of Haribo!',
    extension:'Extended till 24th March',
    selectCountry: 'Select Your Country',
    egypt: 'Egypt',
    bahrain: 'Bahrain',
    oman: 'Oman',
    uae: 'United Arab Emirates',
    kuwait: 'Kuwait',
    qatar: 'Qatar',
    contact: 'Phone Number',
    privacy: 'Privacy',
    terms: 'Terms of use',
    website: 'Haribo website',
    formHeading:'Enter to win great prizes',
    submit:'Submit',
    city:'City',
    email:'Email',
    country:'Country',
    code:'Code',
    name:'Full Name',
    codeHelper:'Enter 16 digit code, numbers and capital letters only',
    queries:'For any queries, you may contact us at:',
    emirate:'Emirate',
    agree:'I agree to all',
    conditions:'Terms & Conditions'

};